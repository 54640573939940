
import Navbar from './Components/Navbar';
import './App.css';
import Textform from './Components/Textform';
import React, {useState} from 'react';

function App() {
  const [mode, setMode] = useState('light');
  const togglemode = ()=>{
    if(mode === 'light'){
      setMode('dark');
      document.body.style.backgroundColor = '#04051e';
      document.body.style.color = 'white';
    }
    else{
      setMode('light');
      document.body.style.backgroundColor = 'white';
      document.body.style.color = 'black';
    }
  }
  return (


    <>
  <Navbar title="Text Utils" mode = {mode} togglemode = {togglemode} ></Navbar>

  <div className="container">
    

  <Textform mode={mode}/>
  </div>
  </>
  );
}

export default App;
