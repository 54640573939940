import React, {useState} from 'react'

export default function Textform(props) {
    const handleUpClick = ()=>{
        let upText = text.toUpperCase();
        setText(upText);
    }

    const handleLoClick = ()=>{
        let loText = text.toLowerCase();
        setText(loText);
    }

    let toCam = function camelize(text) {
        return text.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    const handleCamel = ()=>{
        setText(toCam);
    }

    let toReverseCase = function reverseCase(text) {
        return text.replace(/([a-z]+)|([A-Z]+)/g, function(match, lower, upper) {
        return lower ? match.toUpperCase() : match.toLowerCase();
        });
    }

    const handleReverseCase = ()=>{
        setText(toReverseCase);
    }

    const handleOnChange = (e)=>{
        setText(e.target.value);
    }

    const speak = () => {
        let msg = new SpeechSynthesisUtterance();
        msg.text = text;
        window.speechSynthesis.speak(msg);
    }
    const stopSpeak = () => {
        window.speechSynthesis.cancel();
    }


    const handleSentenceCase = () => {
        let lowerCase = text.toLowerCase();
        let regex = /([^.!?]+[!?.\d\s]+)/g;
        let sentences = lowerCase.match(regex);
        let newText = sentences
        .map((sentence) => {
            return (sentence.charAt(0) >= "a" && sentence.charAt(0) <= "z"
            ? sentence.charAt(0).toUpperCase() + sentence.slice(1)
            : sentence);
        })
        .join("");

        setText(newText);
    };

    const handleTitleCase = () => {
        let lowercase = text.toLowerCase();
        let words = lowercase.split(" ");
        let newWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
        });
        let newText = newWords.join(" ");
        setText(newText);
    };

    const handleReverse = (event) => {
        /* Convert string to array*/
        let strArr = text.split("");
        /* Reverse array*/
        strArr = strArr.reverse();
        /* Convert array to string*/
        let newText = strArr.join("");
        setText(newText);
    };

    const replacecasefunc = () => {
        let existing_text = prompt("Enter which word to replace : ");
        let replaced_text = prompt("Enter New Text");
        setText(text.replaceAll(existing_text, replaced_text))
    }

    const WhiteSpace = () => {
        let updated_text = text.replace(/\s/g, '')
        setText(updated_text)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(text); 
       
    }


    // const count = ()=>{
    //     if (text.length>0){
    //     return text.trim().split(/[ ]+/).length;
    //     }
    //     else{
    //     return 0;
    //     }
    // }



        const [text, setText] = useState('');
        
    return (
        <>
        <div className='container my-4'>
            <h3>Enter The Text To Analyze Below</h3>
            <div className="mb-3">
            <textarea className="form-control" style={{backgroundColor: props.mode==='dark'?'#212529':'white', color: props.mode==='dark'?'white':'black'}} value={text} onChange={handleOnChange} id="mybox" rows="8"></textarea>
            </div>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleUpClick}>Convert To Uppercase</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleLoClick}>Convert To Lowercase</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleCamel}>Convert To Camelcase</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleReverseCase}>Reverse Case</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleSentenceCase}>Sentence Case</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleTitleCase}>Title Case</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleReverse}>Reverse Text</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={WhiteSpace}>Remove Whitespace</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={replacecasefunc}>Find and Replace</button>
            <button disabled={text.length===0} className="btn btn-primary mx-3 my-3" onClick={handleCopy}>Copy Text</button>
            <button disabled={text.length===0} className="btn btn-success mx-3 my-3" onClick={speak}>Speak</button>
            <button disabled={text.length===0} className="btn btn-danger mx-3 my-3" onClick={stopSpeak}>Stop Speak</button>
        </div>
        <div className="container my-4" style={{whiteSpace: 'normal', overflow: 'visible', wordBreak: 'break-word'}}>
            <h2>Your Text Summary</h2>
            {/* <p>{count()} words and {text.length} characters.</p> */}
            <p>{text.split(/\s+/).filter((element)=>{return element.length!==0}).length} words and {text.length} characters</p>
            <h2>Preview: </h2>
            <p>{text.length>0?text:"Nothing to preview!"}</p>
        </div>
        <footer className='my-5'>Copyright © All Rights Reserved For Sarthak Chaudhary</footer>
        </>
    )
}
